<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs :config="breadcrumbsConfig"></breadcrumbs>
  <div class="page-form">
    <FormProxy :form-builder="formBuilder"
               :api-method="apiMethod"
               :submit-button-text="submitButtonText"
               :on-success="onSuccess"
               :on-cancel="onCancel"
    ></FormProxy>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "mmsui/src/Form/FormBuilder";
import FormProxy from "@/components/UI/Form/FormProxy.vue";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";

export default {
  components: { Breadcrumbs, FormProxy },
  data() {
    const pricesGroupId = this.$route.params?.pricesGroupId ?? null
    const isCreate = null === pricesGroupId
    const successCallback = (response) => {
      this.$router.push({ name: 'pricesgroups.all' })
    }
    const cancelCallback = (response) => {
      this.$router.push({ name: 'pricesgroups.all' })
    }

    const formBuilder = new FormBuilder()
    formBuilder
        .addFieldSetField('branch_office_id', 'branch_office_id', {
          label: 'Филиал',
          type: 'select',
          required: true,
          options: [
            {
              text: 'Выбрать..',
              hidden: true
            }
          ],
          isDisabled: () => {
            return !isCreate
          }
        })
        .addFieldSetField('name', 'name', {
          label: 'Название группы',
          type: 'text',
          required: true
        })

    const pageHeader = isCreate ? 'Новая группа цен' : 'Редактирование группы цен'

    return {
      pageHeader: pageHeader,
      breadcrumbsConfig: {
        currentTitle: pageHeader,
        routes: [
          {
            name: 'pricesgroups.all',
            title: 'Группы цен'
          }
        ]
      },
      isCreate: isCreate,
      submitButtonText: isCreate ? 'Добавить' : 'Сохранить',
      onSuccess: successCallback,
      onCancel: cancelCallback,
      formBuilder: formBuilder,
      pricesGroupId: pricesGroupId,
      apiMethod: isCreate ? 'prices-groups/create' : `prices-groups/${pricesGroupId}/update`,
    }
  },
  methods: {
    async getPricesGroup() {
      const successCallback = (response) => {
        if (response.data?.prices_group) {
          const pricesGroup = response.data.prices_group

          for (let fieldName in pricesGroup) {
            if (this.formBuilder.hasField(fieldName)) {
              this.formBuilder.updateFieldParam(fieldName, 'value', pricesGroup[fieldName])
            }
          }
          this.formBuilder.updateFieldParam('branch_office_id', 'value', pricesGroup.branch_office.id)
        }
      }

      await apiTransport({
        apiMethod: `prices-groups/${this.pricesGroupId}`,
        successCallback: successCallback
      })
    },
    async getBranchOffices() {
      const successCallback = (response) => {
        if (response.data?.branch_offices) {
          for (let key in response.data.branch_offices) {
            let branchOffice = response.data.branch_offices[key]

            this.formBuilder.addSelectOption('branch_office_id', {
              value: branchOffice?.id,
              text: branchOffice?.name
            })
          }
        }
      }

      await apiTransport({
        apiMethod: 'branch-offices',
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    if (!this.isCreate) {
      this.$emit('startLoading')
    }
  },
  mounted() {
    // Fetch branch offices first important for mapping
    this.getBranchOffices()
        .then(async () => {
          if (!this.isCreate) {
            await this.getPricesGroup()
          }
        })
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>