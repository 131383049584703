<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs :config="breadcrumbsConfig"></breadcrumbs>
  <div class="page-form">
    <FormProxy :form-builder="formBuilder"
               :api-method="apiMethod"
               :on-success="onSuccess"
               :on-cancel="onCancel"
    ></FormProxy>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "mmsui/src/Form/FormBuilder";
import FormProxy from "@/components/UI/Form/FormProxy.vue";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";

export default {
  components: { Breadcrumbs, FormProxy },
  data() {
    const pricelistId = this.$route.params.pricelistId

    const goToPricelistSummary = () => {
      this.$router.push({ name: 'pricelists.current', params: { pricelistId: pricelistId } })
    }

    const successCallback = (response) => {
      goToPricelistSummary()
    }
    const cancelCallback = (response) => {
      goToPricelistSummary()
    }

    const formBuilder = new FormBuilder()
    formBuilder
        .addFieldSetField('upload_file_config', 'header_lines_count', {
          label: 'Строк в заголовке (сколько строк пропустить)',
          type: 'number',
          required: true,
          value: 0
        })
        .addFieldSetField('upload_file_config', 'sheet_number', {
          label: 'Лист для загрузки',
          type: 'select',
          required: true,
          value: 1
        })
        .addFieldSetField('upload_file_config_delivery', 'file_delivery_period_type', {
          label: 'Срок поставки в прайс-листе',
          type: 'radio',
          required: true,
          value: -1,
          options: [
            {
              value: -1,
              text: 'отсутствует'
            },
            {
              value: 0,
              text: 'указан в часах'
            },
            {
              value: 1,
              text: 'указан в днях'
            }
          ]
        })
        .addFieldSetField('upload_file_config_delivery', 'is_file_delivery_min_max_periods_contains', {
          label: 'В файле есть 2 срока поставки',
          description: 'Минимальный и максимальный',
          type: 'switcher',
          isHidden: () => {
            return !this.hasDeliveryInFile()
          }
        })
        .addFieldSetField('upload_file_config_brand', 'is_fixed_brand_name', {
          label: 'Бренд в прайс-листе',
          type: 'radio',
          required: true,
          value: 0,
          options: [
            {
              value: 0,
              text: 'указан'
            },
            {
              value: 1,
              text: 'отсутствует'
            }
          ]
        })
        .addFieldSetField('upload_file_config_brand', 'default_brand_name', {
          label: 'Общий на весь прайс-лист бренд',
          type: 'text',
          required: true,
          max_length: 60,
          isHidden: () => {
            return this.hasBrandInFile()
          }
        })
        .addFieldSetParams('upload_file_config_other_columns', {
          label: 'Остальные столбцы'
        })

    for (let i = 1; i <= 20; i++) {
      formBuilder.addSelectOption('sheet_number', {
        text: `Лист ${i}`,
        value: i
      })
    }

    const columns = {
      article_column: {
        fieldSetName: 'upload_file_config_other_columns',
        label: 'Столбец с артикулом',
        required: true
      },
      brand_name_column: {
        fieldSetName: 'upload_file_config_brand',
        label: 'Столбец с брендом',
        required: true,
        isHidden: () => {
          return !this.hasBrandInFile()
        }
      },
      label_column: {
        fieldSetName: 'upload_file_config_other_columns',
        label: 'Столбец с наименованием',
        required: true
      },
      quantity_column: {
        fieldSetName: 'upload_file_config_other_columns',
        label: 'Столбец с количеством',
        required: true
      },
      price_column: {
        fieldSetName: 'upload_file_config_other_columns',
        label: 'Столбец с ценой',
        required: true
      },
      delivery_column: {
        fieldSetName: 'upload_file_config_delivery',
        label: 'Столбец со сроком поставки',
        required: true,
        isHidden: () => {
          return !this.hasDeliveryInFile() || this.isFileDeliveryMinMaxPeriodsContains()
        }
      },
      min_delivery_column: {
        fieldSetName: 'upload_file_config_delivery',
        label: 'Столбец с минимальным сроком поставки',
        required: true,
        isHidden: () => {
          return !this.hasDeliveryInFile() || !this.isFileDeliveryMinMaxPeriodsContains()
        }
      },
      max_delivery_column: {
        fieldSetName: 'upload_file_config_delivery',
        label: 'Столбец с максимальным сроком поставки',
        required: true,
        isHidden: () => {
          return !this.hasDeliveryInFile() || !this.isFileDeliveryMinMaxPeriodsContains()
        }
      },
      multiplicity_column: {
        fieldSetName: 'other_columns',
        label: 'Столбец с кратностью (мин. партия)'
      },
      region_column: {
        fieldSetName: 'other_columns',
        label: 'Столбец с регионом'
      },
      storage_name_column: {
        fieldSetName: 'other_columns',
        label: 'Столбец с названием склада'
      }
    }

    for (let fieldName in columns) {
      let column = columns[fieldName]

      formBuilder.addFieldSetField(column.fieldSetName, fieldName, {
        label: column.label,
        type: 'select',
        required: !!column?.required,
        isHidden: column?.isHidden
      })

      if (!!column?.required) {
        formBuilder.addSelectOption(fieldName, {
          text: 'Выбрать..',
          hidden: true
        })
      } else {
        formBuilder.addSelectOption(fieldName, {
          text: 'Отсутствует в прайс-листе'
        })
      }

      for (let i = 1; i <= 26; i++) {
        let letter = String.fromCharCode(64 + i)

        formBuilder.addSelectOption(fieldName, {
          text: `Столбец ${letter} (${i})`,
          value: i
        })
      }
    }

    const pageHeader = 'Столбцы'

    return {
      pageHeader: pageHeader,
      breadcrumbsConfig: {
        currentTitle: pageHeader,
        routes: [
          {
            name: 'pricelists.all',
            title: 'Прайс-листы'
          }
        ]
      },
      onSuccess: successCallback,
      onCancel: cancelCallback,
      formBuilder: formBuilder,
      apiMethod: `pricelists/${pricelistId}/upload-file-config/update`,
      pricelistId: pricelistId
    }
  },
  methods: {
    hasBrandInFile() {
      return 0 === this.formBuilder.getField('is_fixed_brand_name').value
    },
    hasDeliveryInFile() {
      return -1 !== this.formBuilder.getField('file_delivery_period_type').value
    },
    isFileDeliveryMinMaxPeriodsContains() {
      return !!this.formBuilder.getField('is_file_delivery_min_max_periods_contains')?.isEnabled
    },
    async getPricelistUploadFileConfig() {
      const successCallback = (response) => {
        const config = response.data.pricelist_upload_file_config ?? {}

        for (let fieldName in config) {
          if (this.formBuilder.hasField(fieldName)) {
            this.formBuilder.updateFieldParam(fieldName, 'value', config[fieldName])
          }
        }

        this.formBuilder.updateFieldParam(
            'is_file_delivery_min_max_periods_contains',
            'isEnabled',
            config.is_file_delivery_min_max_periods_contains
        )

        for (let columnKey in config?.columns ?? {}) {
          let fieldName = `${columnKey}_column`

          if (this.formBuilder.hasField(fieldName)) {
            this.formBuilder.updateFieldParam(fieldName, 'value', config.columns[columnKey])
          }
        }

        const pricelistName = response.data.pricelist_main_info?.name ?? null

        this.breadcrumbsConfig.routes.push({
          name: 'pricelists.current',
          params: { pricelistId: this.pricelistId },
          title: pricelistName ? `Прайс-лист «${pricelistName}»` : 'Новый прайс-лист'
        })
      }

      await apiTransport({
        apiMethod: `pricelists/${this.pricelistId}/upload-file-config`,
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getPricelistUploadFileConfig()
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>