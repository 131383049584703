import {HttpStatusCode} from "axios";
import store from "@/store/store";
import router from "@/router/router";
import authSuccessCallback from "@/pages/PlatformAuth/authSuccessCallback";
import apiTransportShot from "mmsapitransport/src/apitransport/apiTransport";

const shot = async (params, config) => {
    await apiTransportShot(params, config)
}

const catchCallback = async (err, params) => {
    if (HttpStatusCode.Unauthorized === err.status) {
        if (params?.unauthorizedCallback) {
            params.unauthorizedCallback(err)
        } else if (params?.afterRefreshToken) {
            logoutCallback()
        } else {
            const refreshTokenParams = {
                apiMethod: 'token/refresh',
                successCallback: (response) => {
                    authSuccessCallback(response, async () => {
                        await shot(params, shotConfig)
                    })
                },
                errorCallback: logoutCallback,
                afterRefreshToken: true
            }
            await shot(refreshTokenParams, shotConfig)
        }
    } else if (HttpStatusCode.Forbidden === err.status) {
        if (1 === err.response.data?.reason) {
            router.push({
                name: 'billing.summary'
            })

            return
        }

        pushNotification({
            message: 'Что-то пошло не так'
        }, 'error')
    } else if ('post' !== params.httpMethod && HttpStatusCode.NotFound === err.status) {
        router.push({ name: 'notfoundpage' })
    } else {
        if (err.response?.data) {
            pushNotification(err.response.data, 'error')
        }

        if (params.errorCallback) {
            params.errorCallback(err)
        } else {
            pushNotification({
                message: 'Что-то пошло не так',
                description: 'Попробуйте обновить страницу и повторить действие'
            }, 'error')
        }
    }
}

const logoutCallback = () => {
    store.commit('resetAuth')

    // Check for double sign in form redirect on async requests
    // It's important on "returnUrl" parameter exists (opposite side it will be added again recursive)
    // Other words, redirect one time only
    if ('sign_in' !== router.currentRoute.value.name) {
        router.push({
            name: 'sign_in',
            query: {
                returnUrl: router.currentRoute.value.fullPath
            }
        })
    }
}

const pushNotification = (data, type) => {
    if (!data?.message) {
        return
    }

    store.commit('addPageNotification', {
        label: data.message,
        description: data?.description,
        type: type
    })
}

const getBaseHeadersCallback = () => {
    return {
        'Authorization': 'Bearer ' + store.getters.auth.token,
        'X-Refresh-Token': store.getters.auth.refresh_token
    }
}

const shotConfig = {
    baseUrl: 'https://' + process.env.VUE_APP_API_URL,
    baseHeadersCallback: getBaseHeadersCallback,
    afterSuccessCallback: (response) => {
        pushNotification(response.data, 'success')
    },
    catchCallback: async (err, params) => {
        await catchCallback(err, params)
    }
}

export default async (params) => {
    await shot(params, shotConfig)
}
