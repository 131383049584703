<template>
  <Nav/>
  <MobileNav/>
  <div class="content-wrap">
    <main>
      <router-view
          @start-loading="startPageLoading"
          @finish-loading="finishPageLoading"
      ></router-view>
    </main>
    <div v-if="isPageLoading" class="pl-loader pl-loader-dark"></div>
    <page-notifications-proxy></page-notifications-proxy>
  </div>
</template>

<script>
import Nav from "@/components/Nav/Nav.vue";
import MobileNav from "@/components/Nav/MobileNav.vue";
import PageNotificationsProxy from "@/components/UI/PageNotifications/PageNotificationsProxy.vue";

export default {
  components: {
    PageNotificationsProxy,
    Nav,
    MobileNav
  },
  data() {
    return {
      isPageLoading: false
    }
  },
  methods: {
    startPageLoading: function() {
      this.isPageLoading = true
    },
    finishPageLoading: function() {
      this.isPageLoading = false
    }
  }
}
</script>

<style scoped>
.content-wrap {
  flex-grow: 1;
  position: relative;
  overflow: auto;
}
.content-wrap::before {
  content: '';
  position: absolute;
  visibility: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--body-bg);
  opacity: 0;
  -moz-transition: all 50ms linear;
  -o-transition: all 50ms linear;
  -webkit-transition: all 50ms linear;
  transition: all 50ms linear;
}
main {
  padding: 0 10px 60px;
}
@media (min-width: 500px) {
  main {
    padding: 6px 15px 65px;
  }
}
@media (min-width: 768px) {
  main {
    padding: 18px 30px 30px;
  }
}
</style>