<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs :config="breadcrumbsConfig"></breadcrumbs>
  <div class="page-form">
    <FormProxy :form-builder="formBuilder"
               :api-method="apiMethod"
               :on-success="onSuccess"
               :on-cancel="onCancel"
    ></FormProxy>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "mmsui/src/Form/FormBuilder";
import FormProxy from "@/components/UI/Form/FormProxy.vue";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";
import getCSVDelimiters from "@/pages/Platform/Feeds/Enums/getCSVDelimiters";

export default {
  components: {Breadcrumbs, FormProxy},
  data() {
    const feedId = this.$route.params.feedId

    const goToFeedSummary = () => {
      this.$router.push({ name: 'feeds.current', params: { feedId: feedId } })
    }

    const successCallback = (response) => {
      goToFeedSummary()
    }
    const cancelCallback = (response) => {
      goToFeedSummary()
    }

    const CSVDelimiterFieldOptions = [
      {
        text: 'Выбрать..',
        hidden: true
      }
    ]

    const CSVDelimiters = getCSVDelimiters()
    for (let delimiterKey in CSVDelimiters) {
      CSVDelimiterFieldOptions.push({
        text: CSVDelimiters[delimiterKey],
        value: delimiterKey
      })
    }

    const formBuilder = new FormBuilder()
    formBuilder
        .addFieldSetField('branch_office_id', 'branch_office_id', {
          label: 'Филиал',
          type: 'select',
          required: true,
          options: [
            {
              text: 'Выбрать..',
              hidden: true
            }
          ],
          isDisabled: () => {
            return null !== this.branchOfficeId
          }
        })
        .addFieldSetField('format_id', 'format_id', {
          label: 'Формат',
          type: 'select',
          required: true,
          options: [
            {
              text: 'Выбрать..',
              hidden: true
            }
          ],
          isDisabled: () => {
            return null !== this.formatId
          }
        })
        .addFieldSetFieldsLine(
            'csv_format_config',
            'csv_format_config',
            {
              type: 'text_fields_line',
              fields: [
                {
                  fieldName: 'extension',
                  label: 'Расширение файла',
                  type: 'select',
                  required: true,
                  options: [
                    {
                      text: 'Выбрать..',
                      hidden: true
                    },
                    {
                      text: '.csv',
                      value: 'csv'
                    },
                    {
                      text: '.txt',
                      value: 'txt'
                    },
                    {
                      text: '.tsv',
                      value: 'tsv'
                    }
                  ]
                },
                {
                  fieldName: 'csv_delimiter',
                  label: 'Разделитель полей CSV',
                  type: 'select',
                  required: true,
                  options: CSVDelimiterFieldOptions
                }
              ]
            }
        )
        .addFieldSetParams('csv_format_config', {
          isHidden: () => {
            return !this.isCSVFormatSelected()
          }
        })
        .addFieldSetField('is_enabled', 'is_enabled', {
          label: 'Фид включен',
          description: 'Генерируется, доступен к скачиванию',
          type: 'switcher'
        })
        .addFieldSetField('name', 'name', {
          label: 'Название фида',
          type: 'text',
          required: true
        })

    const pageHeader = 'Основные настройки'

    return {
      pageHeader: pageHeader,
      breadcrumbsConfig: {
        currentTitle: pageHeader,
        routes: [
          {
            name: 'feeds.all',
            title: 'Фиды'
          }
        ]
      },
      formatId: null,
      onSuccess: successCallback,
      onCancel: cancelCallback,
      formBuilder: formBuilder,
      apiMethod: `feeds/${feedId}/main-info/update`,
      feedId: feedId,
      branchOfficeId: null
    }
  },
  methods: {
    isBranchOfficeSet() {
      return null !== this.branchOfficeId
    },
    isCSVFormatSelected() {
      return 2 === parseInt(this.formBuilder.getFieldValueByFieldName('format_id'))
    },
    async getFeedMainInfo() {
      const successCallback = (response) => {
        if (response.data?.feed_main_info) {
          const mainInfo = response.data.feed_main_info

          this.formBuilder.updateFieldParam('name', 'value', mainInfo?.name ?? '')
          this.formBuilder.updateFieldParam('is_enabled', 'isEnabled', !mainInfo?.is_disabled)

          if (mainInfo?.format) {
            this.formatId = mainInfo.format.id
            this.formBuilder.updateFieldParam('format_id', 'value', this.formatId)
          }

          if (mainInfo?.branch_office) {
            this.branchOfficeId = mainInfo.branch_office.id
            this.formBuilder.updateFieldParam('branch_office_id', 'value', this.branchOfficeId)
          }

          if (mainInfo.config?.extension) {
            this.formBuilder.updateFieldParam('extension', 'value', mainInfo.config.extension)
          }

          if (mainInfo.config?.csv_delimiter) {
            this.formBuilder.updateFieldParam('csv_delimiter', 'value', mainInfo.config.csv_delimiter)
          }

          this.breadcrumbsConfig.routes.push({
            name: 'feeds.current',
            params: { feedId: this.feedId },
            title: mainInfo?.name ? `Фид «${mainInfo?.name}»` : 'Новый фид'
          })
        }
      }

      await apiTransport({
        apiMethod: `feeds/${this.feedId}/main-info`,
        successCallback: successCallback
      })
    },
    async getBranchOffices() {
      const successCallback = (response) => {
        if (response.data?.branch_offices) {
          for (let key in response.data.branch_offices) {
            let branchOffice = response.data.branch_offices[key]

            this.formBuilder.addSelectOption('branch_office_id', {
              value: branchOffice?.id,
              text: branchOffice?.name
            })
          }
        }
      }

      await apiTransport({
        apiMethod: 'branch-offices',
        successCallback: successCallback
      })
    },
    async getFeedFormats() {
      const successCallback = (response) => {
        if (response.data?.formats) {
          for (let key in response.data.formats) {
            let format = response.data.formats[key]

            this.formBuilder.addSelectOption('format_id', {
              value: format.id,
              text: format.label
            })
          }
        }
      }

      await apiTransport({
        apiMethod: 'feeds/formats',
        successCallback: successCallback
      })
    },
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    // Fetch feed formats and branch offices first important for mapping
    Promise.all([this.getFeedFormats(), this.getBranchOffices()])
      .then(async () => {
        await this.getFeedMainInfo()
      })
      .then(() => {
        this.$emit('finishLoading')
      })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>