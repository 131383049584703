<template>
  <div class="page-form">
    <h1>
      Настройка интеграции
    </h1>
    <FormProxy :form-builder="formBuilder"
               :api-method="apiMethod"
               :submit-button-text="submitButtonText"
               :on-success="onSuccess"
               :on-cancel="onCancel"
    ></FormProxy>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "mmsui/src/Form/FormBuilder";
import FormProxy from "@/components/UI/Form/FormProxy.vue";

export default {
  components: { FormProxy },
  data() {
    const marketplaceId = this.$route.params?.marketplaceId ?? null
    const isCreate = null === marketplaceId
    const successCallback = (response) => {
      this.$router.push({ name: 'marketplaces.all' })
    }
    const cancelCallback = (response) => {
      this.$router.push({ name: 'marketplaces.all' })
    }

    const formBuilder = new FormBuilder()
    formBuilder
        .addFieldSetField('branch_office_id', 'branch_office_id', {
          label: 'Филиал',
          type: 'select',
          required: true,
          options: [
            {
              text: 'Выбрать..',
              hidden: true
            }
          ],
          isDisabled: () => {
            return !isCreate
          }
        })
        .addFieldSetField('type', 'type', {
          label: 'Маркетплейс',
          type: 'select',
          required: true,
          options: [
            {
              text: 'Выбрать..',
              hidden: true
            }
          ],
          isDisabled: () => {
            return !isCreate
          }
        })
        .addFieldSetField('name', 'name', {
          label: 'Название интеграции',
          type: 'text',
          required: true
        })

    return {
      isCreate: isCreate,
      submitButtonText: isCreate ? 'Добавить' : 'Сохранить',
      onSuccess: successCallback,
      onCancel: cancelCallback,
      formBuilder: formBuilder,
      marketplaceId: marketplaceId,
      apiMethod: isCreate ? 'marketplaces/create' : `marketplaces/${marketplaceId}/update`,
    }
  },
  methods: {
    getSelectedType() {
      return parseInt(this.formBuilder.getFieldValueByFieldName('type'))
    },
    async getMarketplace() {
      const successCallback = (response) => {
        if (response.data?.marketplace) {
          const marketplace = response.data.marketplace

          for (let fieldName in marketplace) {
            if (this.formBuilder.hasField(fieldName)) {
              this.formBuilder.updateFieldParam(fieldName, 'value', marketplace[fieldName])
            }
          }

          for (let configFieldName in marketplace.config) {
            let realConfigFieldName = configFieldName + marketplace.type.id

            if (this.formBuilder.hasField(realConfigFieldName)) {
              this.formBuilder.updateFieldParam(
                  realConfigFieldName,
                  'value',
                  marketplace.config[configFieldName]
              )
            }
          }

          this.formBuilder.updateFieldParam('type', 'value', marketplace.type.id)
          this.formBuilder.updateFieldParam('branch_office_id', 'value', marketplace.branch_office.id)
        }
      }

      await apiTransport({
        apiMethod: `marketplaces/${this.marketplaceId}`,
        successCallback: successCallback
      })
    },
    async getMarketplaceTypes() {
      const successCallback = (response) => {
        if (response.data?.marketplace_types) {
          for (let key in response.data.marketplace_types) {
            let marketplace = response.data.marketplace_types[key]

            this.formBuilder.addSelectOption('type', {
              value: marketplace.id,
              text: marketplace.label
            })

            if (marketplace?.credentials_config) {
              for (let fieldName in marketplace.credentials_config) {
                let fieldId = fieldName + marketplace.id
                let fieldParams = marketplace.credentials_config[fieldName]

                this.formBuilder
                    .addFieldSetField(fieldId, fieldId, {
                      isHidden: () => {
                        return this.getSelectedType() !== marketplace.id
                      },
                      label: fieldParams.field_user_name,
                      type: 'text',
                      required: !!fieldParams?.required
                    })
                    .addFieldSetParams(fieldId, {
                      isHidden: () => {
                        return this.getSelectedType() !== marketplace.id
                      }
                    })
              }
            }
          }
        }
      }

      await apiTransport({
        apiMethod: 'marketplaces/types',
        successCallback: successCallback
      })
    },
    async getBranchOffices() {
      const successCallback = (response) => {
        if (response.data?.branch_offices) {
          for (let key in response.data.branch_offices) {
            let branchOffice = response.data.branch_offices[key]

            this.formBuilder.addSelectOption('branch_office_id', {
              value: branchOffice?.id,
              text: branchOffice?.name
            })
          }
        }
      }

      await apiTransport({
        apiMethod: 'branch-offices',
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    if (!this.isCreate) {
      this.$emit('startLoading')
    }
  },
  mounted() {
    // Fetch marketplace types and branch offices first important for mapping
    Promise.all([this.getMarketplaceTypes(), this.getBranchOffices()])
      .then(async () => {
        if (!this.isCreate) {
          await this.getMarketplace()
        }
      })
      .then(() => {
        this.$emit('finishLoading')
      })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>