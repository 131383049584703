<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs :config="breadcrumbsConfig"></breadcrumbs>
  <div class="page-form">
    <FormProxy :form-builder="formBuilder"
               :api-method="apiMethod"
               :on-success="onSuccess"
               :on-cancel="onCancel"
    ></FormProxy>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "mmsui/src/Form/FormBuilder";
import FormProxy from "@/components/UI/Form/FormProxy.vue";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";

export default {
  components: { Breadcrumbs, FormProxy },
  data() {
    const pricelistId = this.$route.params.pricelistId

    const goToPricelistSummary = () => {
      this.$router.push({ name: 'pricelists.current', params: { pricelistId: pricelistId } })
    }

    const successCallback = (response) => {
      goToPricelistSummary()
    }
    const cancelCallback = (response) => {
      goToPricelistSummary()
    }

    const formBuilder = new FormBuilder()
    formBuilder
        .addFieldSetField('currency_convert_type', 'currency_convert_type', {
          label: 'Конвертировать валюту',
          type: 'radio',
          required: true,
          value: 0,
          options: [
            {
              value: 0,
              text: 'по текущему курсу ЦБ РФ'
            },
            {
              value: 1,
              text: 'по коэффициенту'
            }
          ]
        })
        .addFieldSetField('in_currency_id', 'in_currency_id', {
          label: 'Валюта в прайс-листе',
          type: 'select',
          required: true,
          options: [
            {
              text: 'Выбрать..',
              hidden: true
            }
          ]
        })
        .addFieldSetParams('in_currency_id', {
          isHidden: () => {
            return this.isCurrencyConvertTypeByCoef()
          }
        })
        .addFieldSetField('custom_currency_coef', 'custom_currency_coef', {
          label: 'Валютный коэффициент',
          type: 'text',
          required: true
        })
        .addFieldSetParams('custom_currency_coef', {
          isHidden: () => {
            return !this.isCurrencyConvertTypeByCoef()
          }
        })

    const pageHeader = 'Валюта прайс-листа'

    return {
      pageHeader: pageHeader,
      breadcrumbsConfig: {
        currentTitle: pageHeader,
        routes: [
          {
            name: 'pricelists.all',
            title: 'Прайс-листы'
          }
        ]
      },
      onSuccess: successCallback,
      onCancel: cancelCallback,
      formBuilder: formBuilder,
      apiMethod: `pricelists/${pricelistId}/currency/update`,
      pricelistId: pricelistId
    }
  },
  methods: {
    isCurrencyConvertTypeByCoef() {
      return 1 === this.formBuilder.getField('currency_convert_type').value
    },
    async getPricelistCurrencyConfig() {
      const successCallback = (response) => {
        if (response.data?.pricelist_currency) {
          const currencyConfig = response.data.pricelist_currency
          const pricelistName = response.data.pricelist_main_info?.name ?? null

          this.formBuilder.updateFieldParam('currency_convert_type', 'value', currencyConfig.currency_convert_type)
          this.formBuilder.updateFieldParam('custom_currency_coef', 'value', currencyConfig.custom_currency_coef)
          this.formBuilder.updateFieldParam('in_currency_id', 'value', currencyConfig.in_currency?.id)

          this.breadcrumbsConfig.routes.push({
            name: 'pricelists.current',
            params: { pricelistId: this.pricelistId },
            title: pricelistName ? `Прайс-лист «${pricelistName}»` : 'Новый прайс-лист'
          })
        }
      }

      await apiTransport({
        apiMethod: `pricelists/${this.pricelistId}/currency`,
        successCallback: successCallback
      })
    },
    async getCurrencies() {
      const successCallback = (response) => {
        if (response.data?.currencies) {
          for (let key in response.data.currencies) {
            let currency = response.data.currencies[key]

            this.formBuilder.addSelectOption('in_currency_id', {
              value: currency.id,
              text: currency.full_name
            })
          }
        }
      }

      await apiTransport({
        apiMethod: 'currencies',
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    // Fetch currencies first important for mapping
    this.getCurrencies()
        .then(async () => {
          await this.getPricelistCurrencyConfig()
        })
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>