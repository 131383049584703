<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs :config="breadcrumbsConfig"></breadcrumbs>
  <div class="page-form">
    <FormProxy :form-builder="formBuilder"
               :api-method="apiMethod"
               :submit-button-text="submitButtonText"
               :on-success="onSuccess"
               :on-cancel="onCancel"
    >
      <template v-slot:replace_branch_office_info>
        <div>
          Вы уверены, что хотите удалить филиал «<b>{{ branchOfficeName }}</b>»?
        </div>
        <div>
          После удаления любые данные, которые были связаны с этим филиалом, будут связаны с тем, который Вы выберете для замены.
        </div>
        <div>
          <b>Это действие нельзя будет отменить!</b>
        </div>
      </template>
      <template v-slot:single_branch_office_info>
        <div>
          Нельзя удалять единственный филиал
        </div>
      </template>
    </FormProxy>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormProxy from "@/components/UI/Form/FormProxy.vue";
import FormBuilder from "mmsui/src/Form/FormBuilder";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";

export default {
  components: { Breadcrumbs, FormProxy },
  data() {
    const deleteBranchOfficeId = parseInt(this.$route.params?.branchOfficeId)
    const goToBranchOfficesList = () => {
      this.$router.push({ name: 'branchoffices.all' })
    }
    const successCallback = (response) => {
      goToBranchOfficesList()
    }
    const cancelCallback = (response) => {
      goToBranchOfficesList()
    }

    const hasBranchOfficesForReplace = () => {
      return !!this.forReplaceCount
    }

    const formBuilder = new FormBuilder()
    formBuilder
        .setSubmitDisabledCallback(() => {
          return !hasBranchOfficesForReplace()
        })
        .addFieldSetField('replace_branch_office', 'replace_branch_office_info', {
          slot: 'replace_branch_office_info',
          type: 'infoblock'
        })
        .addFieldSetField('replace_branch_office', 'replace_branch_office_id', {
          label: 'Филиал для замены',
          type: 'select',
          options: [
            {
              text: 'Выбрать..',
              hidden: true
            }
          ],
          required: true
        })
        .addFieldSetParams('replace_branch_office', {
          isHidden: () => {
            return !hasBranchOfficesForReplace()
          }
        })

    const pageHeader = 'Удаление филиала'

    return {
      pageHeader: pageHeader,
      breadcrumbsConfig: {
        currentTitle: pageHeader,
        routes: [
          {
            name: 'branchoffices.all',
            title: 'Филиалы'
          }
        ]
      },
      forReplaceCount: null,
      replaceBranchOffices: [],
      submitButtonText: 'Да, удалить',
      formBuilder: formBuilder,
      onSuccess: successCallback,
      onCancel: cancelCallback,
      deleteBranchOfficeId: deleteBranchOfficeId,
      apiMethod: `branch-offices/${deleteBranchOfficeId}/delete`,
      goToBranchOfficesList: goToBranchOfficesList,
      branchOfficeName: ''
    }
  },
  methods: {
    async getBranchOffice() {
      const successCallback = (response) => {
        if (response.data?.branch_offices) {
          this.forReplaceCount = 0
          for (let key in response.data.branch_offices) {
            let branchOffice = response.data.branch_offices[key]

            if (branchOffice.id === this.deleteBranchOfficeId) {
              this.branchOfficeName = branchOffice.name
            } else {
              this.formBuilder.addSelectOption('replace_branch_office_id', {
                value: branchOffice?.id,
                text: branchOffice?.name
              })

              this.forReplaceCount++
            }
          }

          if (!this.forReplaceCount) {
            this.formBuilder.addFieldSetField(
                'single_branch_office_info',
                'single_branch_office_info',
                {
                  slot: 'single_branch_office_info',
                  type: 'infoblock'
                },
            )
          }
        }
      }

      await apiTransport({
        apiMethod: 'branch-offices',
        successCallback: successCallback
      })
    },
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getBranchOffice()
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>