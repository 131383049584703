<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs :config="breadcrumbsConfig"></breadcrumbs>
  <div class="page-form">
    <FormProxy :form-builder="formBuilder"
               :api-method="apiMethod"
               :submit-button-text="submitButtonText"
               :on-success="onSuccess"
               :on-cancel="onCancel"
    >
      <template v-slot:delete_user_info>
        <div>
          Вы уверены, что хотите удалить аккаунт <b>{{ userEmail }}</b>?
        </div>
        <div>
          Любые данные, которые связаны с этим аккаунтом, больше не будут иметь с ним связи!
        </div>
        <div>
          <b>Это действие нельзя будет отменить!</b>
        </div>
      </template>
    </FormProxy>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "mmsui/src/Form/FormBuilder";
import FormProxy from "@/components/UI/Form/FormProxy.vue";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";

export default {
  components: { Breadcrumbs, FormProxy },
  data() {
    const userId = parseInt(this.$route.params.userId)
    const goToUsersList = () => {
      this.$router.push({ name: 'users.all' })
    }
    const successCallback = (response) => {
      goToUsersList()
    }
    const cancelCallback = (response) => {
      goToUsersList()
    }

    const formBuilder = new FormBuilder()
    formBuilder
        .addFieldSetField('delete_user_info', 'delete_user_info', {
          slot: 'delete_user_info',
          type: 'infoblock'
        })

    const pageHeader = 'Удаление пользователя'

    return {
      pageHeader: pageHeader,
      breadcrumbsConfig: {
        currentTitle: pageHeader,
        routes: [
          {
            name: 'users.all',
            title: 'Пользователи'
          }
        ]
      },
      submitButtonText: 'Да, удалить',
      formBuilder: formBuilder,
      onSuccess: successCallback,
      onCancel: cancelCallback,
      userId: userId,
      apiMethod: `users/${userId}/delete`,
      userEmail: ''
    }
  },
  methods: {
    async getUser() {
      const successCallback = (response) => {
        if (response.data?.user) {
          this.userEmail = response.data.user.email
        }
      }

      await apiTransport({
        apiMethod: `users/${this.userId}`,
        successCallback: successCallback
      })
    },
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getUser()
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>