<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs :config="breadcrumbsConfig"></breadcrumbs>
  <div class="page-form">
    <FormProxy :form-builder="formBuilder"
               :api-method="apiMethod"
               :on-success="onSuccess"
               :on-cancel="onCancel"
    ></FormProxy>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "mmsui/src/Form/FormBuilder";
import FormProxy from "@/components/UI/Form/FormProxy.vue";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";

export default {
  components: { Breadcrumbs, FormProxy },
  data() {
    const pricelistId = this.$route.params.pricelistId

    const goToPricelistSummary = () => {
      this.$router.push({ name: 'pricelists.current', params: { pricelistId: pricelistId } })
    }

    const successCallback = (response) => {
      goToPricelistSummary()
    }
    const cancelCallback = (response) => {
      goToPricelistSummary()
    }

    const formBuilder = new FormBuilder()
    formBuilder
        .addFieldSetField('branch_office_id', 'branch_office_id', {
          label: 'Филиал',
          type: 'select',
          required: true,
          options: [
            {
              text: 'Выбрать..',
              hidden: true
            }
          ],
          isDisabled: () => {
            return this.isBranchOfficeSet()
          }
        })
        .addFieldSetField('is_enabled', 'is_enabled', {
          label: 'Прайс-лист включен',
          description: 'Скачивается, участвует в генерации',
          type: 'switcher'
        })
        .addFieldSetField('name', 'name', {
          label: 'Название прайс-листа',
          type: 'text',
          required: true
        })
        .addFieldSetField('supplier_name', 'supplier_name', {
          label: 'Поставщик',
          type: 'text',
          required: true
        })

    const pageHeader = 'Основные настройки'

    return {
      pageHeader: pageHeader,
      breadcrumbsConfig: {
        currentTitle: pageHeader,
        routes: [
          {
            name: 'pricelists.all',
            title: 'Прайс-листы'
          }
        ]
      },
      onSuccess: successCallback,
      onCancel: cancelCallback,
      formBuilder: formBuilder,
      apiMethod: `pricelists/${pricelistId}/main-info/update`,
      pricelistId: pricelistId,
      branchOfficeId: null
    }
  },
  methods: {
    isBranchOfficeSet() {
      return null !== this.branchOfficeId
    },
    async getPricelistMainInfo() {
      const successCallback = (response) => {
        if (response.data?.pricelist_main_info) {
          const mainInfo = response.data.pricelist_main_info

          this.formBuilder.updateFieldParam('name', 'value', mainInfo?.name ?? '')
          this.formBuilder.updateFieldParam('supplier_name', 'value', mainInfo?.supplier_name ?? '')
          this.formBuilder.updateFieldParam('is_enabled', 'isEnabled', !mainInfo?.is_disabled)

          if (mainInfo?.branch_office) {
            this.branchOfficeId = mainInfo.branch_office.id
            this.formBuilder.updateFieldParam('branch_office_id', 'value', this.branchOfficeId)
          }

          this.breadcrumbsConfig.routes.push({
            name: 'pricelists.current',
            params: { pricelistId: this.pricelistId },
            title: mainInfo?.name ? `Прайс-лист «${mainInfo.name}»` : 'Новый прайс-лист'
          })
        }
      }

      await apiTransport({
        apiMethod: `pricelists/${this.pricelistId}/main-info`,
        successCallback: successCallback
      })
    },
    async getBranchOffices() {
      const successCallback = (response) => {
        if (response.data?.branch_offices) {
          for (let key in response.data.branch_offices) {
            let branchOffice = response.data.branch_offices[key]

            this.formBuilder.addSelectOption('branch_office_id', {
              value: branchOffice?.id,
              text: branchOffice?.name
            })
          }
        }
      }

      await apiTransport({
        apiMethod: 'branch-offices',
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    // Fetch branch offices first important for mapping
    this.getBranchOffices()
        .then(async () => {
          await this.getPricelistMainInfo()
        })
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>