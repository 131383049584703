<template>
  <router-view></router-view>
</template>

<script>

</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap');
@import url('https://zap-shop.ru/skins/fonts/fonts.css');
@import 'mmsui/src/CSS/main.css';
body {
  overflow: hidden !important; /* prevent UIPopup overflow: visible */
}
</style>