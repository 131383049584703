<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs v-if="null !== breadcrumbsConfig" :config="breadcrumbsConfig"></breadcrumbs>
  <div v-if="null !== events" class="page-small">
    <div v-if="events.length" class="info-blocks">
      <div v-for="event in events ?? []" class="info-block">
        <div>
          <h3>{{ event.datetime.formatted }}</h3>
        </div>
        <div>
          <div v-for="textLine in event.text_lines">
            {{ textLine }}
          </div>

          <div v-if="event.additional_data?.header" class="event-additional-info">
            <h3>
              {{ event.additional_data.header }}
            </h3>

            <div v-for="child in event.additional_data?.children ?? []">
              <h4>
                {{ child.header }}
              </h4>
              <div v-for="texLine in child.text_lines">
                {{ texLine }}
              </div>
            </div>

            <div v-for="texLine in event.additional_data?.text_lines ?? []">
              {{ texLine }}
            </div>
          </div>
        </div>
        <div v-if="!isCurrentEvent && event.has_additional_data">
          <button class="xs-button inline-button fs90p" @click="this.$router.push({
            name: 'events.current',
            params: {
              eventId: event.id
            }
          })">
            Подробнее
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      Не выполнено ни одного действия
    </div>
    <div ref="events_finish"></div>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";

export default {
  components: {Breadcrumbs},
  data() {
    return {
      limitPerLoad: 20,
      lastLoadEventId: null,
      infinityScrollObserver: null,
      eventId: null,
      isCurrentEvent: false,
      pageHeader: '',
      breadcrumbsConfig: null,
      events: null,
      eventsParams: null
    }
  },
  methods: {
    refreshComponent() {
      const eventsParams = {}

      if (this.$route.query?.entity) {
        eventsParams.entity = this.$route.query.entity

        if (this.$route.query?.entity_id) {
          eventsParams.entity_id = this.$route.query.entity_id
        }
      }

      if (this.$route.query?.action) {
        eventsParams.action = this.$route.query.action
      }

      if (this.$route.query?.user_id) {
        eventsParams.user_id = this.$route.query.user_id
      }

      if (this.$route.query?.event_id) {
        eventsParams.event_id = this.$route.query.event_id
      }

      eventsParams.limit = this.limitPerLoad

      this.eventsParams = eventsParams

      const pageHeader = 'Журнал'

      this.pageHeader = pageHeader
      this.breadcrumbsConfig = {
        currentTitle: pageHeader,
            routes: []
      }

      // Reset all properties before get new events
      const eventId = this.$route.params?.eventId ?? null

      this.eventId = eventId
      this.isCurrentEvent = null !== eventId
      this.lastLoadEventId = null
      this.events = null

      this.getEvents()
          .then(() => {
            this.$emit('finishLoading')
          })
    },
    initInfinityScrollObserver() {
      this.disconnectInfinityScrollObserver() // important for prevent detached active observers

      this.$nextTick(() => {
        this.infinityScrollObserver = new IntersectionObserver(entries => {
          if (entries[0].intersectionRatio > 0) {
            this.getEvents()
          }
        })

        this.infinityScrollObserver.observe(this.$refs.events_finish)
      })
    },
    disconnectInfinityScrollObserver() {
      if (null !== this.infinityScrollObserver) {
        this.infinityScrollObserver.disconnect()
        this.infinityScrollObserver = null
      }
    },
    async getEvents() {
      const successCallback = (response) => {
        const events = response.data.events

        if (null === this.events) {
          this.events = []
        }

        const filterLabel = response.data.filter_label

        // Add nesting, if filter label set and it is not load on scroll
        if (null === this.lastLoadEventId && null !== filterLabel) {
          this.breadcrumbsConfig.routes.push({
            name: 'events.all',
            title: this.pageHeader
          })

          this.breadcrumbsConfig.currentTitle = filterLabel
        }

        let newLastLoadedEventId = this.lastLoadEventId
        for (let key in events) {
          this.events.push(events[key])

          newLastLoadedEventId = events[key].id
        }

        this.lastLoadEventId = newLastLoadedEventId

        if (this.limitPerLoad === events.length) {
          this.initInfinityScrollObserver()
        } else {
          this.disconnectInfinityScrollObserver()
        }
      }

      this.eventsParams.sep_event_id = this.lastLoadEventId

      await apiTransport({
        apiMethod: this.isCurrentEvent ? `events/${this.eventId}` : 'events',
        queryParams: this.isCurrentEvent ? null : this.eventsParams,
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.refreshComponent()
  },
  emits: [
    'startLoading',
    'finishLoading'
  ],
  watch: {
    // Vue don't reload component on change query parameters with no change route
    '$route.query' () {
      this.$emit('startLoading')
      this.refreshComponent()
    }
  }
}
</script>

<style scoped>
.event-additional-info h3 {
  margin-block-start: 1em;
}
.event-additional-info h4 {
  margin-block-start: 0.8em;
  margin-block-end: 0.2em;
}
</style>