<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs :config="breadcrumbsConfig"></breadcrumbs>
  <div class="page-small">
    <div v-if="null !== activePeriodTill" class="info-blocks">
      <div class="info-block">
        <h3>
          Тариф «{{ tariff.name }}»
        </h3>
        <template v-if="isPeriodOver">
          <div class="fwb">
            Оплаченный период истек {{ activePeriodTill }}
          </div>
        </template>
        <template v-else>
          <div>
            <span class="grey-color">
              Сгенерировано предложений:
            </span> {{ offersGenerated.used.count }} / {{ offersGenerated.total }} – <span
                :class="[ 'fwb', offersGenerated.colorClass ]"
            >
              {{ offersGenerated.used.percent }}%
            </span>
          </div>
          <div>
            <span class="grey-color">
              Предложений в прайс-листах:
            </span> {{ pricelistsOffers.used.count }} / {{ pricelistsOffers.total }} – <span
                :class="[ 'fwb', pricelistsOffers.colorClass ]"
            >
              {{ pricelistsOffers.used.percent }}%
            </span>
          </div>
          <div>
            <span class="grey-color">
              Оплаченный период истекает:
            </span> <b>{{ activePeriodTill }}</b>
          </div>
        </template>
        <div>
          <button v-if="tariff.is_test_drive"
                  @click="goToTariffsPage"
          >
            Выбрать тариф
          </button>
          <FormProxy v-else
                     :form-builder="formBuilder"
                     :api-method="apiMethod"
                     :submit-button-text="submitButtonText"
                     :on-success="goToPayPage"
                     :cancel-button-text="cancelButtonText"
                     :on-cancel="goToTariffsPage"
          ></FormProxy>
        </div>
      </div>
    </div>

    <h2>
      История платежей
    </h2>
    <PlatformInvoicesList :is-current-invoice="false"
                          :invoices="invoices"
                          :add-all-invoices-list-button="5 === invoices?.length"
    />
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";
import FormProxy from "@/components/UI/Form/FormProxy.vue";
import FormBuilder from "mmsui/src/Form/FormBuilder";
import PlatformInvoicesList from "@/pages/Platform/PlatformInvoices/PlatformInvoicesList.vue";

export default {
  components: {PlatformInvoicesList, FormProxy, Breadcrumbs},
  data() {
    const pageHeader = 'Биллинг'

    const formBuilder = new FormBuilder()
    formBuilder.addFixedPayload({
      type: 1 // 1 is next period
    })

    const goToPayPageCallback = (response) => {
      if (response.data?.invoice) {
        console.log(response.data.invoice)
      }
    }

    const goToTariffsPageCallback = () => {
      this.$router.push({
        name: 'billing.tariffs'
      })
    }

    return {
      pageHeader: pageHeader,
      breadcrumbsConfig: {
        currentTitle: pageHeader
      },
      invoices: null,
      isPeriodOver: false,
      activePeriodTill: null,
      tariff: null,
      offersGenerated: null,
      pricelistsOffers: null,

      submitButtonText: 'Продлить',
      cancelButtonText: 'Изменить тариф',
      formBuilder: formBuilder,
      goToPayPage: goToPayPageCallback,
      goToTariffsPage: goToTariffsPageCallback,
      apiMethod: 'billing/invoices/create'
    }
  },
  methods: {
    getTariffCounterPercentColorClass(percent) {
      if (percent < 70) {
        return 'success-color'
      }

      if (percent >= 90) {
        return 'error-color'
      }

      return 'warning-color'
    },
    async getBillingSummary() {
      const successCallback = (response) => {
        if (response.data?.billing_summary) {
          this.activePeriodTill = response.data.billing_summary.active_period_till
          this.tariff = response.data.billing_summary.tariff

          const counter = response.data.billing_summary?.counter ?? null
          this.isPeriodOver = null === counter

          if (this.isPeriodOver) {
            return
          }

          this.offersGenerated = counter.offers_generated
          this.pricelistsOffers = counter.pricelists_offers

          this.offersGenerated.colorClass = this.getTariffCounterPercentColorClass(this.offersGenerated?.used?.percent ?? 0)
          this.pricelistsOffers.colorClass = this.getTariffCounterPercentColorClass(this.pricelistsOffers?.used?.percent ?? 0)
        }
      }

      await apiTransport({
        apiMethod: 'billing/summary',
        successCallback: successCallback
      })
    },
    async getLastInvoices() {
      const successCallback = (response) => {
        if (response.data?.invoices) {
          this.invoices = response.data.invoices
        }
      }

      await apiTransport({
        apiMethod: 'billing/invoices',
        queryParams: {
          limit: 5
        },
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    // Fetch billing info and last invoices async
    Promise.all([this.getBillingSummary(), this.getLastInvoices()])
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>