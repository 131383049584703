<template>
  <div v-if="invoicesForShow?.length" class="info-blocks">
    <div v-for="invoice in invoicesForShow ?? []" class="info-block">
      <h3>
        Платеж №{{ invoice.id }}
      </h3>
      <div v-if="invoice.extended_info">
        <template v-if="invoice.extended_info?.period">
          <div>
            <div>
              <span class="grey-color">
                Тариф:
              </span> <b>{{ invoice.extended_info.tariff.name }}</b>
            </div>
            <div>
              <span class="grey-color">
                Период:
              </span> <b>{{ invoice.extended_info.period.start.formatted }} – {{ invoice.extended_info.period.finish.formatted }}</b>
            </div>
          </div>
        </template>
      </div>
      <div>
        <span class="grey-color">Сумма:</span> <b>{{ invoice.amount }}</b>
      </div>
      <div>
          <span class="grey-color">
            {{ invoice.created_at.formatted }}
          </span>
      </div>
      <div v-if="!isCurrentInvoice">
        <button class="xs-button inline-button fs90p" @click="this.$router.push({
            name: 'billing.invoices.current',
            params: {
              invoiceId: invoice.id
            }
          })"
        >
          Подробнее
        </button>
      </div>
    </div>

    <div v-if="addAllInvoicesListButton">
      <button @click="this.$router.push({ name: 'billing.invoices.all' })"
              class="inline-button"
      >
        Все платежи
      </button>
    </div>
  </div>
  <div v-else-if="null !== invoicesForShow">
    Платежей пока нет
  </div>
</template>

<script>
export default {
  props: {
    invoices: null,
    addAllInvoicesListButton: {
      type: Boolean,
      default: false
    },
    isCurrentInvoice: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      invoicesForShow: this.invoices
    }
  },
  watch: {
    invoices(newValue) {
      this.invoicesForShow = newValue
    }
  }
}
</script>