<template>
  <div class="page-form">
    <h1>
      Удаление маркетплейса
    </h1>
    <FormProxy :form-builder="formBuilder"
               :api-method="apiMethod"
               :submit-button-text="submitButtonText"
               :on-success="onSuccess"
               :on-cancel="onCancel"
    >
      <template v-slot:delete_marketplace_info>
        <div>
          Вы уверены, что хотите удалить маркетплейс <b>{{ marketplaceName }}</b>?
        </div>
        <div>
          <b>Это действие нельзя будет отменить!</b>
        </div>
      </template>
    </FormProxy>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "mmsui/src/Form/FormBuilder";
import FormProxy from "@/components/UI/Form/FormProxy.vue";

export default {
  components: { FormProxy },
  data() {
    const marketplaceId = parseInt(this.$route.params.marketplaceId)
    const goToMarketplacesList = () => {
      this.$router.push({ name: 'marketplaces.all' })
    }
    const successCallback = (response) => {
      goToMarketplacesList()
    }
    const cancelCallback = (response) => {
      goToMarketplacesList()
    }

    const formBuilder = new FormBuilder()
    formBuilder
        .addFieldSetField('delete_marketplace_info', 'delete_marketplace_info', {
          slot: 'delete_marketplace_info',
          type: 'infoblock'
        })

    return {
      submitButtonText: 'Да, удалить',
      formBuilder: formBuilder,
      onSuccess: successCallback,
      onCancel: cancelCallback,
      marketplaceId: marketplaceId,
      apiMethod: `marketplaces/${marketplaceId}/delete`,
      marketplaceName: ''
    }
  },
  methods: {
    async getMarketplace() {
      const successCallback = (response) => {
        if (response.data?.marketplace) {
          this.marketplaceName = response.data.marketplace.name
        }
      }

      await apiTransport({
        apiMethod: `marketplaces/${this.marketplaceId}`,
        successCallback: successCallback
      })
    },
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getMarketplace()
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>