<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs :config="breadcrumbsConfig"></breadcrumbs>
  <div class="page-xsmall">
    <div class="info-blocks">
      <div v-for="tariff in tariffs" class="info-block">
        <h3>
          {{ tariff.name }}
        </h3>
        <div>
          <span class="grey-color">
            Генерация фидов:
          </span> <b>{{ tariff.generation_offers_per_month_limit }}</b> поз./мес.
        </div>
        <div>
          <span class="grey-color">
            Предложений в прайс-листах:
          </span> <b>{{ tariff.pricelists_offers_limit }}</b> шт.
        </div>
        <div>
          <span class="grey-color">
            Цена:
          </span> <b>{{ tariff.monthly_fee }}</b> руб./мес.
        </div>
        <FormProxy :form-builder="tariff.formBuilder"
                   :api-method="changeTariffApiMethod"
                   :submit-button-text="changeTariffSubmitButtonText"
                   :on-success="goToPayPage"
        ></FormProxy>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";
import apiTransport from "@/apitransport/apiTransport";
import FormProxy from "@/components/UI/Form/FormProxy.vue";
import FormBuilder from "mmsui/src/Form/FormBuilder";

export default {
  components: {FormProxy, Breadcrumbs},
  data() {
    const pageHeader = 'Выбор тарифа'

    const goToPayPageCallback = (response) => {
      if (response.data?.invoice) {
        console.log(response.data.invoice)
      }
    }

    return {
      pageHeader: pageHeader,
      breadcrumbsConfig: {
        currentTitle: pageHeader,
        routes: [
          {
            name: 'billing.summary',
            title: 'Биллинг'
          }
        ]
      },
      tariffs: [],
      changeTariffApiMethod: 'billing/invoices/create',
      changeTariffSubmitButtonText: 'Перейти на тариф',
      goToPayPage: goToPayPageCallback
    }
  },
  methods: {
    async getTariffs() {
      const successCallback = (response) => {
        if (response.data?.tariffs) {
          this.tariffs = response.data.tariffs

          for (let tariffKey in this.tariffs) {
            let formBuilder = new FormBuilder()
            formBuilder.addFixedPayload({
              type: 1, // 1 is next period
              tariff_id: this.tariffs[tariffKey].id
            })

            this.tariffs[tariffKey].formBuilder = formBuilder
          }
        }
      }

      await apiTransport({
        apiMethod: 'billing/tariffs',
        queryParams: {
          filters: {
            changelist: 1
          }
        },
        successCallback: successCallback
      })
    },
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getTariffs()
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>

<style scoped>

</style>