<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs :config="breadcrumbsConfig"></breadcrumbs>
  <div class="page-form">
    <FormProxy :form-builder="personalInfoFormBuilder"
               :api-method="personalInfoFormApiMethod"
    ></FormProxy>
    <h2>
      E-mail
    </h2>
    <FormProxy :form-builder="emailFormBuilder"
               :api-method="emailFormApiMethod"
               :submit-button-text="emailFormSubmitButtonText"
               :on-success="emailFormOnSuccess"
    ></FormProxy>
    <h2>
      Изменение пароля
    </h2>
    <FormProxy :form-builder="passwordFormBuilder"
               :api-method="passwordFormApiMethod"
               :submit-button-text="passwordFormSubmitButtonText"
               :on-success="passwordFormOnSuccess"
    ></FormProxy>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "mmsui/src/Form/FormBuilder";
import FormProxy from "@/components/UI/Form/FormProxy.vue";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";

export default {
  components: { Breadcrumbs, FormProxy },
  data() {
    const emailFormOnSuccess = () => {
      this.emailFormBuilder.updateFieldParam('email', 'value', '')
    }

    const passwordFormOnSuccess = () => {
      this.passwordFormBuilder.updateFieldParam('old_password', 'value', '')
      this.passwordFormBuilder.updateFieldParam('new_password', 'value', '')
      this.passwordFormBuilder.updateFieldParam('repeat_new_password', 'value', '')
    }

    const personalInfoFormBuilder = new FormBuilder()
    personalInfoFormBuilder
        .addFieldSetField('name', 'name', {
          label: 'Имя',
          type: 'text',
          required: true
        })
        .addFieldSetField('last_name', 'last_name', {
          label: 'Фамилия',
          type: 'text',
          required: true
        })
        .addFieldSetField('middle_name', 'middle_name', {
          label: 'Отчество',
          type: 'text',
          required: true
        })
        .addFieldSetField('branch_office_config', 'timezone_id', {
          label: 'Часовой пояс',
          type: 'select',
          options: [
            {
              text: 'Выбрать..',
              hidden: true
            }
          ],
          required: true
        })

    const emailFormBuilder = new FormBuilder()
    emailFormBuilder
        .addFieldSetField('email_label', 'email_label', {
          label: '',
          type: 'label'
        })
        .addFieldSetField('email', 'email', {
          label: 'Новый E-mail',
          type: 'text',
          required: true
        })

    const passwordFormBuilder = new FormBuilder()
    passwordFormBuilder
        .addFieldSetField('old_password', 'old_password', {
          label: 'Старый пароль',
          type: 'password',
          required: true
        })
        .addFieldSetField('new_password', 'new_password', {
          label: 'Новый пароль',
          type: 'password',
          required: true
        })
        .addFieldSetField('repeat_new_password', 'repeat_new_password', {
          label: 'Повторите новый пароль',
          type: 'password',
          required: true
        })

    const pageHeader = 'Настройки аккаунта'

    return {
      pageHeader: pageHeader,
      breadcrumbsConfig: {
        currentTitle: pageHeader
      },
      personalInfoFormBuilder: personalInfoFormBuilder,
      personalInfoFormApiMethod: 'account/personal/update',

      currentEmail: '',
      emailFormBuilder: emailFormBuilder,
      emailFormApiMethod: 'account/email/change-request',
      emailFormSubmitButtonText: 'Изменить E-mail',
      emailFormOnSuccess: emailFormOnSuccess,

      passwordFormBuilder: passwordFormBuilder,
      passwordFormApiMethod: 'account/password/new',
      passwordFormSubmitButtonText: 'Изменить пароль',
      passwordFormOnSuccess: passwordFormOnSuccess
    }
  },
  methods: {
    async getAccount() {
      const successCallback = (response) => {
        if (response.data?.account) {
          for (let fieldName in response.data.account) {
            if (this.personalInfoFormBuilder.hasField(fieldName)) {
              this.personalInfoFormBuilder.updateFieldParam(fieldName, 'value', response.data.account[fieldName])
            }
          }

          this.emailFormBuilder.updateFieldParam(
              'email_label',
              'label',
              'Текущий: ' + response.data.account.email
          )

          // Map timezone id
          this.personalInfoFormBuilder.updateFieldParam(
              'timezone_id',
              'value',
              response.data.account?.timezone?.id // TODO remove question marks
          )
        }
      }

      await apiTransport({
        apiMethod: 'account/get',
        successCallback: successCallback
      })
    },
    async getTimezones() {
      const successCallback = (response) => {
        if (response.data?.timezones) {
          for (let key in response.data.timezones) {
            let timezone = response.data.timezones[key]

            this.personalInfoFormBuilder.addSelectOption('timezone_id', {
              value: timezone.id,
              text: `${timezone.user_name} (UTC ${timezone.offset.user_formatted})`
            })
          }
        }
      }

      await apiTransport({
        apiMethod: 'dictionary/timezones',
        queryParams: {
          include: ['Europe', 'Asia']
        },
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    // Fetch timezones first important for mapping
    this.getTimezones()
        .then(async () => {
          await this.getAccount()
        })
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>