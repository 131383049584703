import { createRouter, createWebHistory } from "vue-router";
import auth from "@/middleware/auth";
import guest from "@/middleware/guest";
import Summary from "@/pages/Platform/Summary/Summary.vue";
import PricelistsPage from "@/pages/Platform/Pricelists/PricelistsPage.vue";
import AccountSettingsPage from "@/pages/Platform/AccountSettings/AccountSettingsPage.vue";
import UsersPage from "@/pages/Platform/Users/UsersPage.vue";
import CompaniesPage from "@/pages/Platform/Companies/CompaniesPage.vue";
import FeedsPage from "@/pages/Platform/Feeds/FeedsPage.vue";
import BranchOfficesPage from "@/pages/Platform/BranchOffices/BranchOfficesPage.vue";
import SignInPage from "@/pages/PlatformAuth/SignInPage.vue";
import LogoutPage from "@/pages/PlatformAuth/LogoutPage.vue";
import PasswordRestorePage from "@/pages/PlatformAuth/PasswordRestorePage.vue";
import middlewarePipeline from "@/router/middlewarePipeline";
import store from "@/store/store";
import PlatformBase from "@/pages/Platform/Base.vue";
import AuthBase from "@/pages/PlatformAuth/Base.vue";
import CompanyEditPage from "@/pages/Platform/Companies/CompanyEditPage.vue";
import BranchOfficeEditPage from "@/pages/Platform/BranchOffices/BranchOfficeEditPage.vue";
import UserEditPage from "@/pages/Platform/Users/UserEditPage.vue";
import BranchOfficeDeletePage from "@/pages/Platform/BranchOffices/BranchOfficeDeletePage.vue";
import UserDeletePage from "@/pages/Platform/Users/UserDeletePage.vue";
import CompanyDeletePage from "@/pages/Platform/Companies/CompanyDeletePage.vue";
import MarketplaceEditPage from "@/pages/Platform/Marketplaces/MarketplaceEditPage.vue";
import MarketplaceDeletePage from "@/pages/Platform/Marketplaces/MarketplaceDeletePage.vue";
import MarketplacesPage from "@/pages/Platform/Marketplaces/MarketplacesPage.vue";
import Billing from "@/pages/Platform/Billing/Billing.vue";
import ShopSettings from "@/pages/Platform/ShopSettings/ShopSettings.vue";
import CurrentPricelistPage from "@/pages/Platform/Pricelists/CurrentPricelistPage.vue";
import PricelistEditMainInfoPage from "@/pages/Platform/Pricelists/PricelistEditMainInfoPage.vue";
import PricelistEditDeliveryPage from "@/pages/Platform/Pricelists/PricelistEditDeliveryPage.vue";
import PricelistEditCurrencyPage from "@/pages/Platform/Pricelists/PricelistEditCurrencyPage.vue";
import PricelistEditFiltersPage from "@/pages/Platform/Pricelists/PricelistEditFiltersPage.vue";
import PricelistUploadFileConfigPage from "@/pages/Platform/Pricelists/PricelistUploadFileConfigPage.vue";
import PricelistDeletePage from "@/pages/Platform/Pricelists/PricelistDeletePage.vue";
import PricelistEditDownloadPage from "@/pages/Platform/Pricelists/PricelistEditDownloadPage.vue";
import CurrentFeedPage from "@/pages/Platform/Feeds/CurrentFeedPage.vue";
import FeedEditMainInfoPage from "@/pages/Platform/Feeds/FeedEditMainInfoPage.vue";
import FeedDeletePage from "@/pages/Platform/Feeds/FeedDeletePage.vue";
import EmailProfileDeletePage from "@/pages/Platform/EmailProfiles/EmailProfileDeletePage.vue";
import EmailProfileEditPage from "@/pages/Platform/EmailProfiles/EmailProfileEditPage.vue";
import EmailProfilesPage from "@/pages/Platform/EmailProfiles/EmailProfilesPage.vue";
import checkEditAdminsRole from "@/middleware/checkEditAdminsRole";
import checkEditBranchOfficesCompaniesRole from "@/middleware/checkEditBranchOfficesCompaniesRole";
import checkEditPricelistsRole from "@/middleware/checkEditPricelistsRole";
import checkEditFeedsRole from "@/middleware/checkEditFeedsRole";
import checkEditSettingsRole from "@/middleware/checkEditSettingsRole";
import NotFoundPage from "@/pages/SystemPages/NotFoundPage.vue";
import PricesGroupsPage from "@/pages/Platform/PricesGroups/PricesGroupsPage.vue";
import PricesGroupEditPage from "@/pages/Platform/PricesGroups/PricesGroupEditPage.vue";
import PricesGroupDeletePage from "@/pages/Platform/PricesGroups/PricesGroupDeletePage.vue";
import MarkupsConfigsPage from "@/pages/Platform/MarkupsConfigs/MarkupsConfigsPage.vue";
import MarkupsConfigDeletePage from "@/pages/Platform/MarkupsConfigs/MarkupsConfigDeletePage.vue";
import MarkupsConfigEditPage from "@/pages/Platform/MarkupsConfigs/MarkupsConfigEditPage.vue";
import FeedEditOffersConfigPage from "@/pages/Platform/Feeds/FeedEditOffersConfigPage.vue";
import FeedEditSchedulePage from "@/pages/Platform/Feeds/FeedEditSchedulePage.vue";
import FeedEditPricelistsPage from "@/pages/Platform/Feeds/FeedEditPricelistsPage.vue";
import AccountActivatePage from "@/pages/PlatformAuth/AccountActivatePage.vue";
import EmailChangeConfirmPage from "@/pages/PlatformAuth/EmailChangeConfirmPage.vue";
import NewPasswordPage from "@/pages/PlatformAuth/NewPasswordPage.vue";
import EventsPage from "@/pages/Platform/Events/EventsPage.vue";
import PricelistContentPreviewPage from "@/pages/Platform/Pricelists/PricelistContentPreviewPage.vue";
import BillingInvoicesPage from "@/pages/Platform/Billing/BillingInvoicesPage.vue";
import BillingTariffsPage from "@/pages/Platform/Billing/BillingTariffsPage.vue";

const routes = [
    {
        path: '/auth',
        component: AuthBase,
        meta: {
            middleware: [
                guest
            ]
        },
        children: [
            {
                name: 'sign_in',
                path: '',
                component: SignInPage
            },
            {
                name: 'password_restore',
                path: 'password-restore',
                component: PasswordRestorePage
            }
        ]
    },
    {
        path: '/confirm',
        component: AuthBase,
        children: [
            {
                name: 'account_activate',
                path: 'new-account',
                component: AccountActivatePage
            },
            {
                name: 'change_email',
                path: 'change-email',
                component: EmailChangeConfirmPage
            },
            {
                name: 'new_password',
                path: 'new-password',
                component: NewPasswordPage
            }
        ]
    },
    {
        path: '/',
        component: PlatformBase,
        meta: {
            middleware: [
                auth
            ]
        },
        children: [
            {
                path: '',
                name: 'main',
                redirect: {
                    name: 'summary'
                }
            },
            {
                name: 'logout',
                path: 'logout',
                component: LogoutPage
            },
            {
                name: 'summary',
                path: 'summary',
                component: Summary
            },
            {
                name: 'billing',
                path: 'billing',
                children: [
                    {
                        name: 'billing.summary',
                        path: '',
                        component: Billing
                    },
                    {
                        name: 'billing.tariffs',
                        path: 'tariffs',
                        component: BillingTariffsPage
                    },
                    {
                        name: 'billing.invoices',
                        path: 'invoices',
                        children: [
                            {
                                name: 'billing.invoices.all',
                                path: '',
                                component: BillingInvoicesPage
                            },
                            {
                                name: 'billing.invoices.current',
                                path: ':invoiceId(\\d+)',
                                component: BillingInvoicesPage,
                            }
                        ]
                    }
                ]
            },
            {
                name: 'events',
                path: 'events',
                children: [
                    {
                        name: 'events.all',
                        path: '',
                        component: EventsPage,
                    },
                    {
                        name: 'events.current',
                        path: ':eventId(\\d+)',
                        component: EventsPage,
                    }
                ]
            },
            {
                name: 'goods',
                path: 'goods',
                children: [
                    {
                        path: 'pricelists',
                        meta: {
                            middleware: [
                                checkEditPricelistsRole
                            ]
                        },
                        children: [
                            {
                                name: 'pricelists.all',
                                path: '',
                                component: PricelistsPage
                            },
                            {
                                path: ':pricelistId(\\d+)',
                                children: [
                                    {
                                        name: 'pricelists.current',
                                        path: '',
                                        component: CurrentPricelistPage
                                    },
                                    {
                                        path: 'edit',
                                        children: [
                                            {
                                                name: 'pricelists.current.edit.main',
                                                path: 'main',
                                                component: PricelistEditMainInfoPage
                                            },
                                            {
                                                name: 'pricelists.current.content_preview',
                                                path: 'content-preview',
                                                component: PricelistContentPreviewPage
                                            },
                                            {
                                                name: 'pricelists.current.edit.delivery',
                                                path: 'delivery',
                                                component: PricelistEditDeliveryPage
                                            },
                                            {
                                                name: 'pricelists.current.edit.currency',
                                                path: 'currency',
                                                component: PricelistEditCurrencyPage
                                            },
                                            {
                                                name: 'pricelists.current.edit.filters',
                                                path: 'filters',
                                                component: PricelistEditFiltersPage
                                            },
                                            {
                                                name: 'pricelists.current.edit.upload_file_config',
                                                path: 'upload-file-config',
                                                component: PricelistUploadFileConfigPage
                                            },
                                            {
                                                name: 'pricelists.current.edit.download_config',
                                                path: 'download-config',
                                                component: PricelistEditDownloadPage
                                            },
                                            {
                                                name: 'pricelists.current.delete',
                                                path: 'delete',
                                                component: PricelistDeletePage
                                            }
                                        ]
                                    },
                                    {
                                        path: 'markups',
                                        children: [
                                            {
                                                name: 'pricelists.current.markups_configs.all',
                                                path: 'add',
                                                component: MarkupsConfigsPage
                                            },
                                            {
                                                name: 'pricelists.current.markups_configs.add',
                                                path: 'add',
                                                component: MarkupsConfigEditPage
                                            },
                                            {
                                                path: ':markupsConfigId(\\d+)',
                                                children: [
                                                    {
                                                        name: 'pricelists.current.markups_configs.current.edit',
                                                        path: 'edit',
                                                        component: MarkupsConfigEditPage
                                                    },
                                                    {
                                                        name: 'pricelists.current.markups_configs.current.delete',
                                                        path: 'delete',
                                                        component: MarkupsConfigDeletePage
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        path: 'feeds',
                        meta: {
                            middleware: [
                                checkEditFeedsRole
                            ]
                        },
                        children: [
                            {
                                name: 'feeds.all',
                                path: '',
                                component: FeedsPage
                            },
                            {
                                path: ':feedId(\\d+)',
                                children: [
                                    {
                                        name: 'feeds.current',
                                        path: '',
                                        component: CurrentFeedPage
                                    },
                                    {
                                        path: 'edit',
                                        children: [
                                            {
                                                name: 'feeds.current.edit.main',
                                                path: 'main',
                                                component: FeedEditMainInfoPage
                                            },
                                            {
                                                name: 'feeds.current.edit.offers_config',
                                                path: 'offers-config',
                                                component: FeedEditOffersConfigPage
                                            },
                                            {
                                                name: 'feeds.current.edit.schedule',
                                                path: 'schedule',
                                                component: FeedEditSchedulePage
                                            },
                                            {
                                                name: 'feeds.current.edit.pricelists',
                                                path: 'pricelists',
                                                component: FeedEditPricelistsPage
                                            },
                                            {
                                                name: 'feeds.current.delete',
                                                path: 'delete',
                                                component: FeedDeletePage
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'prices-groups',
                        children: [
                            {
                                name: 'pricesgroups.all',
                                path: '',
                                component: PricesGroupsPage
                            },
                            {
                                name: 'pricesgroups.add',
                                path: 'add',
                                component: PricesGroupEditPage
                            },
                            {
                                path: ':pricesGroupId(\\d+)',
                                children: [
                                    {
                                        name: 'pricesgroups.current.edit',
                                        path: 'edit',
                                        component: PricesGroupEditPage
                                    },
                                    {
                                        name: 'pricesgroups.current.delete',
                                        path: 'delete',
                                        component: PricesGroupDeletePage
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                path: 'settings',
                meta: {
                    middleware: [
                        checkEditSettingsRole
                    ]
                },
                children: [
                    {
                        path: 'shop',
                        children: [
                            {
                                name: 'shop.settings',
                                path: '',
                                component: ShopSettings
                            }
                        ]
                    },
                    {
                        path: 'marketplaces',
                        children: [
                            {
                                name: 'marketplaces.all',
                                path: '',
                                component: MarketplacesPage
                            },
                            {
                                name: 'marketplaces.add',
                                path: 'add',
                                component: MarketplaceEditPage
                            },
                            {
                                path: ':marketplaceId(\\d+)',
                                children: [
                                    {
                                        name: 'marketplaces.current.edit',
                                        path: 'edit',
                                        component: MarketplaceEditPage
                                    },
                                    {
                                        name: 'marketplaces.current.delete',
                                        path: 'delete',
                                        component: MarketplaceDeletePage
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'users',
                        meta: {
                            middleware: [
                                checkEditAdminsRole
                            ]
                        },
                        children: [
                            {
                                name: 'users.all',
                                path: '',
                                component: UsersPage
                            },
                            {
                                name: 'users.add',
                                path: 'add',
                                component: UserEditPage
                            },
                            {
                                path: ':userId(\\d+)',
                                children: [
                                    {
                                        name: 'users.current.edit',
                                        path: 'edit',
                                        component: UserEditPage
                                    },
                                    {
                                        name: 'users.current.delete',
                                        path: 'delete',
                                        component: UserDeletePage
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'email-profiles',
                        children: [
                            {
                                name: 'emailprofiles.all',
                                path: '',
                                component: EmailProfilesPage
                            },
                            {
                                name: 'emailprofiles.add',
                                path: 'add',
                                component: EmailProfileEditPage
                            },
                            {
                                path: ':emailProfileId(\\d+)',
                                children: [
                                    {
                                        name: 'emailprofiles.current.edit',
                                        path: 'edit',
                                        component: EmailProfileEditPage
                                    },
                                    {
                                        name: 'emailprofiles.current.delete',
                                        path: 'delete',
                                        component: EmailProfileDeletePage
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'companies',
                        meta: {
                            middleware: [
                                checkEditBranchOfficesCompaniesRole
                            ]
                        },
                        children: [
                            {
                                name: 'companies.all',
                                path: '',
                                component: CompaniesPage
                            },
                            {
                                name: 'companies.add',
                                path: 'add',
                                component: CompanyEditPage
                            },
                            {
                                path: ':companyId(\\d+)',
                                children: [
                                    {
                                        name: 'companies.current.edit',
                                        path: 'edit',
                                        component: CompanyEditPage
                                    },
                                    {
                                        name: 'companies.current.delete',
                                        path: 'delete',
                                        component: CompanyDeletePage
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'branch-offices',
                        meta: {
                            middleware: [
                                checkEditBranchOfficesCompaniesRole
                            ]
                        },
                        children: [
                            {
                                name: 'branchoffices.all',
                                path: '',
                                component: BranchOfficesPage
                            },
                            {
                                name: 'branchoffices.add',
                                path: 'add',
                                component: BranchOfficeEditPage
                            },
                            {
                                path: ':branchOfficeId(\\d+)',
                                children: [
                                    {
                                        name: 'branchoffices.current.edit',
                                        path: 'edit',
                                        component: BranchOfficeEditPage
                                    },
                                    {
                                        name: 'branchoffices.current.delete',
                                        path: 'delete',
                                        component: BranchOfficeDeletePage
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        name: 'account.settings',
                        path: 'account',
                        component: AccountSettingsPage
                    },
                ]
            }
        ]
    },
    // Next route needs for detect redirect, don't push it from code!
    // It must be defined before "notfoundpage" route for rewrite current
    {
        path: '/:pathMatch(.*)*',
        name: 'notfoundpage.dontpushit',
        component: NotFoundPage
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'notfoundpage',
        component: NotFoundPage
    }
]

const router = createRouter({
    routes,
    history: createWebHistory()
})

router.beforeEach((to, from, next) => {
    if ('notfoundpage' === to.name) {
        return next({
            name: 'notfoundpage.dontpushit',
            params: { pathMatch: from.path.substring(1).split('/') },
            query: from.query,
            hash: from.hash
        })
    }

    // Collect all middlewares from parent routes level asc
    const allMiddlewaresFromParent = []
    for (let matchedKey in to.matched) {
        let matchedMiddlewares = to.matched[to.matched]?.meta?.middleware ?? []
        for (let middlewareKey in matchedMiddlewares) {
            allMiddlewaresFromParent.push(matchedMiddlewares[middlewareKey])
        }
    }

    // Add all destination route middlewares
    if (to?.meta?.middleware?.length) {
        for (let middlewareKey in to.meta.middleware) {
            allMiddlewaresFromParent.push(to.meta.middleware[middlewareKey])
        }
    }

    if (!allMiddlewaresFromParent.length) {
        return next()
    }

    const context = {
        to,
        from,
        next,
        store
    }

    return allMiddlewaresFromParent[0]({
        ...context,
        next: middlewarePipeline(context, allMiddlewaresFromParent, 1)
    })
})

export default router