<template>
  <main>
    <router-view
        @start-loading="startPageLoading"
        @finish-loading="finishPageLoading"
    ></router-view>
    <div v-if="isPageLoading" class="pl-loader pl-loader-dark"></div>
    <page-notifications-proxy></page-notifications-proxy>
  </main>
</template>

<script>
import PageNotificationsProxy from "@/components/UI/PageNotifications/PageNotificationsProxy.vue";

export default {
  components: {
    PageNotificationsProxy
  },
  data() {
    return {
      isPageLoading: false
    }
  },
  methods: {
    startPageLoading: function() {
      this.isPageLoading = true
    },
    finishPageLoading: function() {
      this.isPageLoading = false
    }
  }
}
</script>

<style>
main {
  flex-grow: 1;
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.page-center,
.page-form,
.page-form-center {
  width: 100%;
  max-width: 500px;
}
.page-center,
.page-form-center {
  margin: auto;
}
.page-form-center h1 {
  text-align: center;
}
</style>