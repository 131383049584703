<template>
  <div class="page-form-center">
    <h1>
      Вход в панель управления
    </h1>
    <FormProxy :form-builder="formBuilder"
               :api-method="apiMethod"
               :submit-button-text="submitButtonText"
               :on-success="onSuccess"
               :on-unauthorized="onUnauthorized"
    >
      <template v-slot:auth_links_block>
        <div style="display: flex; justify-content: space-between;">
          <div>
            <a :href="headAppTestDriveRegisterPage" target="_blank" rel="nofollow">
              Зарегистрироваться
            </a>
          </div>
          <div>
            <router-link :to="{ name: 'password_restore' }">
              Забыли пароль?
            </router-link>
          </div>
        </div>
      </template>
    </FormProxy>
  </div>
</template>

<script>
import authSuccessCallback from "@/pages/PlatformAuth/authSuccessCallback";
import FormBuilder from "mmsui/src/Form/FormBuilder";
import FormProxy from "@/components/UI/Form/FormProxy.vue";

export default {
  components: { FormProxy },
  data() {
    const formBuilder = new FormBuilder()
    formBuilder
        .addFieldSetField('auth', 'username', {
          label: 'E-mail',
          type: 'text'
        })
        .addFieldSetField('auth', 'password', {
          label: 'Пароль',
          type: 'password'
        })
        .addFieldSetField('auth', 'auth_links_block', {
          slot: 'auth_links_block',
          type: 'htmlblock'
        })

    return {
      headAppTestDriveRegisterPage: '//' + process.env.VUE_APP_HEAD_APP_URL + '/test-drive',
      submitButtonText: 'Войти',
      onSuccess: (response) => {
        authSuccessCallback(response, () => {
          if (this.$route.query?.returnUrl) {
            this.$router.push(this.$route.query.returnUrl)
          } else {
            this.$router.push({ name: 'summary' })
          }
        })
      },
      onUnauthorized: (err) => {
        // Method prevents refresh JWT request and map user errors

        formBuilder.cleanAllErrors()

        const message = err.response.data?.message ?? null
        if (null !== message) {
          formBuilder.setFieldErrors('username', [{
            message: message
          }])
        }
      },
      formBuilder: formBuilder,
      apiMethod: 'token/get'
    }
  }
}
</script>