<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs :config="breadcrumbsConfig"></breadcrumbs>
  <div class="page-form">
    <FormProxy :form-builder="formBuilder"
               :api-method="apiMethod"
               :submit-button-text="submitButtonText"
               :on-success="onSuccess"
               :on-cancel="onCancel"
    >
      <template v-slot:delete_feed_info>
        <div>
          Вы уверены, что хотите удалить фид <b>{{ feedName }}</b>?
        </div>
        <div>
          <b>Это действие нельзя будет отменить!</b>
        </div>
      </template>
    </FormProxy>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormProxy from "@/components/UI/Form/FormProxy.vue";
import FormBuilder from "mmsui/src/Form/FormBuilder";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";

export default {
  components: { Breadcrumbs, FormProxy },
  data() {
    const feedId = parseInt(this.$route.params.feedId)
    const goToFeedsList = () => {
      this.$router.push({ name: 'feeds.all' })
    }
    const successCallback = (response) => {
      goToFeedsList()
    }
    const cancelCallback = (response) => {
      goToFeedsList()
    }

    const formBuilder = new FormBuilder()

    formBuilder
        .addFieldSetField('delete_feed_info', 'delete_feed_info', {
          slot: 'delete_feed_info',
          type: 'infoblock'
        })

    const pageHeader = 'Удаление фида'

    return {
      pageHeader: pageHeader,
      breadcrumbsConfig: {
        currentTitle: pageHeader,
        routes: [
          {
            name: 'feeds.all',
            title: 'Фиды'
          }
        ]
      },
      submitButtonText: 'Да, удалить',
      formBuilder: formBuilder,
      onSuccess: successCallback,
      onCancel: cancelCallback,
      feedId: feedId,
      apiMethod: `feeds/${feedId}/delete`,
      feedName: ''
    }
  },
  methods: {
    async getFeed() {
      const successCallback = (response) => {
        if (response.data?.feed) {
          const feed = response.data.feed

          this.feedName = feed?.name ? feed.name : 'Без названия'
        }
      }

      await apiTransport({
        apiMethod: `feeds/${this.feedId}`,
        successCallback: successCallback
      })
    },
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getFeed()
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>