<template>
  <page-notifications :get-page-notifications="getPageNotificationsCallback"></page-notifications>
</template>

<script>
import PageNotifications from "mmsui/src/PageNotifications/PageNotifications.vue";

export default {
  components: { PageNotifications },
  data() {
    return {
      getPageNotificationsCallback: () => {
        return this.$store.getters.getPageNotifications
      }
    }
  }
}
</script>