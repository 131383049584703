<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs :config="breadcrumbsConfig"></breadcrumbs>
  <div class="page-form">
    <FormProxy :form-builder="formBuilder"
               :api-method="apiMethod"
               :submit-button-text="submitButtonText"
               :on-success="onSuccess"
               :on-cancel="onCancel"
    >
      <template v-slot:delete_prices_group_info>
        <div>
          Вы уверены, что хотите удалить группу цен <b>{{ pricesGroupName }}</b>?
        </div>
        <div>
          Вместе с группой цен будут удалены <b>все</b> наценки прайс-листов, использующих данную группу цен.
        </div>
        <div>
          <b>Это действие нельзя будет отменить!</b>
        </div>
      </template>
    </FormProxy>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "mmsui/src/Form/FormBuilder";
import FormProxy from "@/components/UI/Form/FormProxy.vue";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";

export default {
  components: { Breadcrumbs, FormProxy },
  data() {
    const pricesGroupId = parseInt(this.$route.params.pricesGroupId)
    const goToPricesGroupsList = () => {
      this.$router.push({ name: 'pricesgroups.all' })
    }
    const successCallback = (response) => {
      goToPricesGroupsList()
    }
    const cancelCallback = (response) => {
      goToPricesGroupsList()
    }

    const formBuilder = new FormBuilder()
    formBuilder
        .addFieldSetField('delete_prices_group_info', 'delete_prices_group_info', {
          slot: 'delete_prices_group_info',
          type: 'infoblock'
        })

    const pageHeader = 'Удаление группы цен'

    return {
      pageHeader: pageHeader,
      breadcrumbsConfig: {
        currentTitle: pageHeader,
        routes: [
          {
            name: 'pricesgroups.all',
            title: 'Группы цен'
          }
        ]
      },
      submitButtonText: 'Да, удалить',
      formBuilder: formBuilder,
      onSuccess: successCallback,
      onCancel: cancelCallback,
      pricesGroupId: pricesGroupId,
      apiMethod: `prices-groups/${pricesGroupId}/delete`,
      pricesGroupName: ''
    }
  },
  methods: {
    async getPricesGroup() {
      const successCallback = (response) => {
        if (response.data?.prices_group) {
          this.pricesGroupName = response.data.prices_group.name
        }
      }

      await apiTransport({
        apiMethod: `prices-groups/${this.pricesGroupId}`,
        successCallback: successCallback
      })
    },
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getPricesGroup()
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>