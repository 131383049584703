<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs v-if="null !== breadcrumbsConfig" :config="breadcrumbsConfig"></breadcrumbs>
  <div v-if="null !== invoices" class="page-small">
    <PlatformInvoicesList :is-current-invoice="isCurrentInvoice"
                          :invoices="invoices"
                          :add-all-invoices-list-button="false"
    />
    <div ref="invoices_finish"></div>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";
import PlatformInvoicesList from "@/pages/Platform/PlatformInvoices/PlatformInvoicesList.vue";

export default {
  components: {PlatformInvoicesList, Breadcrumbs},
  data() {
    return {
      limitPerLoad: 20,
      lastLoadInvoiceId: null,
      infinityScrollObserver: null,
      invoiceId: null,
      isCurrentInvoice: false,
      pageHeader: '',
      breadcrumbsConfig: null,
      invoices: null,
      invoicesParams: null
    }
  },
  methods: {
    refreshComponent() {
      // Reset all properties before get new invoices
      this.invoiceId = this.$route.params?.invoiceId ?? null

      this.isCurrentInvoice = null !== this.invoiceId

      this.invoicesParams = {}

      if (this.isCurrentInvoice) {
        this.invoicesParams.extended = 1
      } else {
        this.invoicesParams.limit = this.limitPerLoad
      }

      const pageHeader = 'История платежей'

      this.pageHeader = pageHeader
      this.breadcrumbsConfig = {
        currentTitle: pageHeader,
        routes: [
          {
            name: 'billing.summary',
            title: 'Биллинг'
          }
        ]
      }

      this.lastLoadInvoiceId = null
      this.invoices = null

      this.getInvoices()
          .then(() => {
            this.$emit('finishLoading')
          })
    },
    initInfinityScrollObserver() {
      this.disconnectInfinityScrollObserver() // important for prevent detached active observers

      this.$nextTick(() => {
        this.infinityScrollObserver = new IntersectionObserver(entries => {
          if (entries[0].intersectionRatio > 0) {
            this.getInvoices()
          }
        })

        this.infinityScrollObserver.observe(this.$refs.invoices_finish)
      })
    },
    disconnectInfinityScrollObserver() {
      if (null !== this.infinityScrollObserver) {
        this.infinityScrollObserver.disconnect()
        this.infinityScrollObserver = null
      }
    },
    async getInvoices() {
      const successCallback = (response) => {
        const invoices = this.isCurrentInvoice ? [response.data.invoice] : response.data.invoices

        if (null === this.invoices) {
          this.invoices = []
        }

        // Add nesting, if filter label set and it is not load on scroll
        if (null !== this.invoiceId) {
          this.breadcrumbsConfig.routes.push({
            name: 'billing.invoices.all',
            title: this.pageHeader
          })

          this.breadcrumbsConfig.currentTitle = `Платеж №${this.invoiceId}`
        }

        let newLastLoadedInvoiceId = this.lastLoadInvoiceId
        for (let key in invoices) {
          this.invoices.push(invoices[key])

          newLastLoadedInvoiceId = invoices[key].id
        }

        this.lastLoadInvoiceId = newLastLoadedInvoiceId

        if (this.limitPerLoad === invoices.length) {
          this.initInfinityScrollObserver()
        } else {
          this.disconnectInfinityScrollObserver()
        }
      }

      this.invoicesParams.sep_invoice_id = this.lastLoadInvoiceId

      await apiTransport({
        apiMethod: this.isCurrentInvoice ? `billing/invoices/${this.invoiceId}` : 'billing/invoices',
        queryParams: this.invoicesParams,
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.refreshComponent()
  },
  emits: [
    'startLoading',
    'finishLoading'
  ],
  watch: {
    // Vue don't reload component on change query parameters with no change route
    '$route.query' () {
      this.$emit('startLoading')
      this.refreshComponent()
    }
  }
}
</script>
