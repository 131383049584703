<template>
  <Form v-bind="$attrs"
        :api-transport="formApiTransport"
  >
    <!-- Pass all slots down (see https://vue-land.github.io/faq/forwarding-slots#passing-all-slots) -->
    <template
        v-for="(_, slotName) in $slots"
        v-slot:[slotName]="slotProps"
    >
      <slot :name="slotName" v-bind="slotProps ?? {}" />
    </template>
  </Form>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import Form from "mmsui/src/Form/Form.vue";

export default {
  components: { Form },
  data() {
    return {
      formApiTransport: (params) => {
        apiTransport(params)
      }
    }
  }
}
</script>