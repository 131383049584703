<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs :config="breadcrumbsConfig"></breadcrumbs>
  <div v-if="null !== positions" class="page-middle info-blocks">
    <div v-if="positions.length" class="info-block">
      <div>
        <b>Выводятся максимум {{ positionsLimit }} позиций из прайс-листа.</b> Данная таблица необходима лишь для оценки корректности настройки столбцов прайс-листа.
      </div>
    </div>
    <div v-if="positions.length" class="ui-table fs80p content-preview-table">
      <div class="ui-table-header">
        <div class="ui-table-cell">
          Артикул
        </div>
        <div class="ui-table-cell">
          Бренд
        </div>
        <div class="ui-table-cell">
          Наименование
        </div>
        <div class="ui-table-cell ta-c">
          Кол-во
        </div>
        <div class="ui-table-cell ta-c">
          Цена
        </div>
        <div class="ui-table-cell ta-c">
          Кратн.
        </div>
        <div class="ui-table-cell ta-r">
          Срок
        </div>
      </div>
      <div v-for="position in positions" class="ui-table-row">
        <div class="ui-table-cell">
          {{ position.article }}
        </div>
        <div class="ui-table-cell">
          {{ position.brand }}
        </div>
        <div class="ui-table-cell">
          {{ position.label }}
          <template v-if="position.region">
            <br />
            <span class="grey-color">
              Регион:
            </span> {{ position.region }}
          </template>
          <template v-if="position.storageName">
            <br />
            <span class="grey-color">
              Склад:
            </span> {{ position.storageName }}
          </template>
        </div>
        <div class="ui-table-cell ta-c">
          {{ position.quantity }}
        </div>
        <div class="ui-table-cell ta-c">
          {{ position.price }}
        </div>
        <div class="ui-table-cell ta-c">
          {{ position.multiplicity }}
        </div>
        <div class="ui-table-cell ta-r">
          {{ position.delivery.user_format }}
        </div>
      </div>
    </div>
    <div v-else>
      Не загружено ни одной позиции
    </div>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";

export default {
  components: { Breadcrumbs },
  data() {
    const pricelistId = this.$route.params.pricelistId

    const pageHeader = 'Предпросмотр содержимого'

    return {
      positionsLimit: 30,
      pageHeader: pageHeader,
      breadcrumbsConfig: {
        currentTitle: pageHeader,
        routes: [
          {
            name: 'pricelists.all',
            title: 'Прайс-листы'
          }
        ]
      },
      pricelistId: pricelistId,
      positions: null
    }
  },
  methods: {
    async getPricelistMainInfo() {
      const successCallback = (response) => {
        this.positions = response.data.positions

        const mainInfo = response.data.pricelist_main_info

        this.breadcrumbsConfig.routes.push({
          name: 'pricelists.current',
          params: { pricelistId: this.pricelistId },
          title: mainInfo?.name ? `Прайс-лист «${mainInfo.name}»` : 'Новый прайс-лист'
        })
      }

      await apiTransport({
        apiMethod: `pricelists/${this.pricelistId}/content-preview`,
        queryParams: {
          limit: this.positionsLimit
        },
        successCallback: successCallback
      })
    },
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getPricelistMainInfo()
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>
<style scoped>
.ui-table {
  border-radius: var(--primary-br);
  background: var(--primary-blocks-bg);
  color: var(--primary-color);
  box-shadow: var(--primary-blocks-shadow);
  overflow: hidden;
}
.ui-table-header {
  background: var(--primary-contrast-bg);
  color: var(--primary-contrast-color);
}
.ui-table-header,
.ui-table-row {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-bottom: var(--table-border);
}
.ui-table-header:last-child,
.ui-table-row:last-child {
  border-bottom: 0;
}
.ui-table-cell {
  padding: 10px;
}
.content-preview-table .ui-table-header,
.content-preview-table .ui-table-row {
  grid-template-columns: 90px 80px auto 70px 70px 70px 70px;
}
.content-preview-table .ui-table-cell {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>