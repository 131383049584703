<template>
  <form @submit.prevent="uploadFile">
    <div class="form-field-wrap">
      <input style="display: none;"
             :id="'pricelist_file'"
             :type="'file'"
             :accept="accept.join(',')"
             :ref="'pricelistFile'"
             @change="updateUploadFileInfo($event)"
      />
      <div class="buttons-wrap">
        <button type="button"
                @click="!isUploadDisabled && !isSubmitHandling && uploadFileClick()"
                :class="{
                  'btn-disabled': isUploadDisabled || isSubmitHandling
                }"
        >
          {{ null === selectedFile ? noFileSelectedButtomText : fileSelectedButtomText }}
        </button>
        <button v-if="null !== selectedFile && !isUploadDisabled"
                type="submit"
                :class="{
                  'btn-disabled': isSubmitHandling
                }"
        >
          Загрузить
        </button>
        <button v-if="null !== selectedFile && !isSubmitHandling && !isUploadDisabled"
                type="button"
                @click="cleanField()"
        >
          Отмена
        </button>
      </div>
      <div v-if="null !== selectedFile">
        {{ selectedFile.name }}
      </div>
      <div v-else-if="!isSubmitHandling && 0 === updateState?.status">
        Выберите новый прайс-лист для загрузки
      </div>
      <div v-else-if="!isSubmitHandling && 0 !== updateState?.status">
        {{ updateState.text }}
      </div>
    </div>
  </form>

  <div class="last-event-info-container">
    <div v-if="isSubmitHandling || fileUploadProgressText > 1"
         class="last-event-info progress"
    >
      <div class="progress-bar"
           :class="{
              'progress-bar-success': 100 === fileUploadProgressText
           }"
           :style="{
              width: `${fileUploadProgress}%`
           }"
      >
      </div>
      <div>
        {{ fileUploadProgressText }}%
      </div>
    </div>

    <div v-else-if="10 === updateState?.status"
         class="last-event-info progress-processing"
    >
      <div>
        Читаем прайс-лист и загружаем предложения
      </div>
    </div>

    <div v-else-if="null === lastUpdateContentActionEvent.id"
         class="last-event-info"
    >
      <div>
        Прайс-лист ни разу не загружался
      </div>
    </div>

    <div v-else class="last-event-info"
         :class="{
                 'last-event-info-success': lastUpdateContentActionEvent.success,
                 'last-event-info-error': !lastUpdateContentActionEvent.success
               }"
    >
      <div v-if="lastUpdateContentActionEvent.success">
        Обновлен <b>{{ lastUpdateContentActionEvent.datetime?.formatted }}</b>
      </div>
      <div v-else>
        Ошибка обновления <b>{{ lastUpdateContentActionEvent.datetime?.formatted }}</b>
      </div>
      <button class="xs-button"
              @click="this.$router.push({
                      name: 'events.current',
                      params: {
                        eventId: lastUpdateContentActionEvent.id
                      }
                    })"
      >
        Отчет
      </button>
    </div>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import "@/pages/Platform/Events/css/last_event.css";
import "@/pages/Platform/Pricelists/css/progress.css";

export default {
  props: {
    pricelistId: null,
    pricelistUpdateState: null,
    isPricelistDisabled: {
      type: Boolean,
      default: false
    },
    lastUpdateContentActionEvent: Object
  },
  data() {
    return {
      updateState: this.pricelistUpdateState,
      noFileSelectedButtomText: 'Выбрать прайс-лист',
      fileSelectedButtomText: 'Выбрать другой прайс-лист',
      accept: [
        '.csv',
        '.txt',
        'text/plain',
        '.xlsm',
        '.xls',
        '.xlsx',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel,application/vnd.ms-excel.sheet.macroEnabled.12'
      ],
      selectedFile: null,
      isSubmitHandling: false,
      isSubmitDisabled: false,
      isUploadDisabled: this.isPricelistDisabled || 0 !== this.pricelistUpdateState?.status,
      fileUploadProgress: 0,
      fileUploadProgressText: 0
    }
  },
  methods: {
    updateUploadFileInfo(event) {
      if (!event.target.files.length) {
        this.cleanField()
      } else {
        const file = event.target.files[0]

        this.selectedFile = {
          file: file,
          name: file.name
        }
      }
    },
    uploadFileClick() {
      this.$refs.pricelistFile.click()
    },
    cleanField() {
      this.fileUploadProgress = 0
      this.fileUploadProgressText = 0
      this.selectedFile = null

      // Reset file in input
      this.$refs.pricelistFile.value = null
    },
    async uploadFile() {
      if (this.isSubmitDisabled || null === this.selectedFile) {
        return
      }

      let progressCounterId
      let isUploadedSuccess = false

      const successCallback = (response) => {
        this.updateState = response.data.new_update_state
        this.isUploadDisabled = 0 !== response.data.new_update_state.status
        isUploadedSuccess = true
      }

      const errorCallback = (err) => {
        clearInterval(progressCounterId)
        this.cleanField()
      }

      const finallyCallback = () => {
        this.isSubmitHandling = false
      }

      const payload = {
        pricelist_file: this.selectedFile.file
      }

      this.isSubmitHandling = true

      // Animate percents count text. On success animate counter up to 100 and stop animation
      progressCounterId = setInterval(() => {
        if ((isUploadedSuccess && this.fileUploadProgressText < 100)
            || this.fileUploadProgress > this.fileUploadProgressText
        ) {
          this.fileUploadProgressText++
        } else if (isUploadedSuccess && 100 === this.fileUploadProgressText) {
          clearInterval(progressCounterId)

          // Wait 1.1 seconds to 1 seconds animation complete
          setTimeout(() => {
            this.cleanField()
          }, 1100)
        }
      }, 10)

      await apiTransport({
        apiMethod: `pricelists/${this.pricelistId}/upload`,
        httpMethod: 'post',
        payload: payload,
        contentType: 'multipart/form-data',
        successCallback: successCallback,
        finallyCallback: finallyCallback,
        onUploadProgress: (progressEvent) => {
          this.fileUploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        }
      })
    }
  },
  watch: {
    pricelistUpdateState(newValue) {
      this.updateState = newValue
      this.isUploadDisabled = 0 !== newValue.status
    }
  }
}
</script>